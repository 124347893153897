import React from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

const ChurnTitle = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isFirstOperation = queryParams.get('operation') === 'first';

  return (
    <Stack width={{ xs: 350, md: 450, lg: 600 }}>
      <Typography fontSize={{ xs: 14, md: 16, lg: 24 }} lineHeight={1.3}>
        Para tu {isFirstOperation ? 'primera' : 'próxima'} operación:
      </Typography>
      <Typography
        fontWeight="bold"
        fontSize={{ xs: 16, md: 24, lg: 32 }}
        lineHeight={1.3}
      >
        Adelanta hoy
      </Typography>
      <Typography
        fontWeight="bold"
        fontSize={{ xs: 16, md: 24, lg: 32 }}
        lineHeight={1.3}
      >
        el pago de tus facturas{' '}
      </Typography>
      <Typography
        fontWeight="bold"
        fontSize={{ xs: 16, md: 24, lg: 32 }}
        lineHeight={1.3}
      >
        a{' '}
        <Typography
          component="span"
          color="primary"
          fontWeight="bold"
          fontSize={{ xs: 16, md: 24, lg: 32 }}
        >
          tasa 0.99%{' '}
        </Typography>
        en operaciones de hasta{' '}
        <Typography
          component="span"
          color="primary"
          fontWeight="bold"
          fontSize={{ xs: 16, md: 24, lg: 32 }}
        >
          $20.000.000
        </Typography>
      </Typography>
    </Stack>
  );
};

export default ChurnTitle;

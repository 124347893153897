import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import MuiIt from '@mui/material/ListItem';
import MuiLIc from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import styled from 'styled-components';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import { passwordCheckers } from './passwordCheckers';

const ListItem = styled(MuiIt)({
  padding: 0,
});
const ListItemIcon = styled(MuiLIc)({
  padding: 0,
  minWidth: 0,
});

const PasswordStrength = ({ password }) => {
  const getIcon = (isTrueCallback) => {
    if (isTrueCallback(password)) {
      return <Check size="small" color="success" />;
    }
    return <Clear color="error" size="small" />;
  };
  return (
    <Stack width="100%" alignItems="flex-start">
      <List>
        {passwordCheckers.map(({ label, regexChecker }) => (
          <ListItem key={label}>
            <ListItemIcon>
              {getIcon(regexChecker)}
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
};

export default PasswordStrength;
